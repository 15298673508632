import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Hydroo1 from "/src/components/img/hydroo/1"
import Hydroo2 from "/src/components/img/hydroo/2"


export default () => (
  <Layout>
    <SEO title="Členění světového oceánu" />
    <h1>Členění světového oceánu</h1>
    <p>Světov&yacute; oce&aacute;n lze z&aacute;kladně rozčlenit na <strong>jednotliv&eacute; oce&aacute;ny a moře</strong>. Jsou v&scaron;ak četn&eacute; odli&scaron;n&eacute; způsoby, jak dělit <Link to="/hydrologie/svetovy-ocean/">světov&yacute; oce&aacute;n</Link>, ty jsou pak pops&aacute;ny d&aacute;le v&nbsp;kapitole.</p>
<hr /><h2>Členěn&iacute; na oce&aacute;ny a moře</h2>
<p><strong>Oce&aacute;n</strong> je dle definice <a href="http://doi.io-warnemuende.de/10.12754/msr-2019-0109">Ericha Brunse</a> z roku 1958 č&aacute;st světov&eacute;ho oce&aacute;nu lež&iacute;c&iacute; mezi pevninami s vlastn&iacute;m uzavřen&yacute;m syst&eacute;mem proudů, vodn&iacute;ch mas, syst&eacute;mem dnov&yacute;ch sedimentů a s typick&yacute;m rozdělen&iacute;m teplot a salinity.</p>
<p><strong>Moře</strong> je č&aacute;st oce&aacute;nu zasahuj&iacute;c&iacute; do pevniny, nebo je oddělen&aacute; od oce&aacute;nu řetězcem ostrovů. Moře se d&aacute;l děl&iacute; na:</p>
<ul>
<li><strong>okrajov&aacute;</strong> = od oce&aacute;nu oddělen&eacute; jedn&iacute;m či 2 plochami pevniny či řetězcem ostrovů; od oce&aacute;nu se př&iacute;li&scaron; neli&scaron;&iacute; teplotou a salinitou, tvoř&iacute; okraj oce&aacute;nu; Severn&iacute; m., Beringovo m.</li>
<li><strong>vnitřn&iacute;</strong> = t&eacute;měř &uacute;plně obklopen&eacute; pevninou, s oce&aacute;nem spojen&eacute; jen jedn&iacute;m či v&iacute;ce průlivy; Čern&eacute; m., Baltsk&eacute; m. a Středozemn&iacute; (to m&aacute; i vlastn&iacute; proudov&yacute; syst&eacute;m)</li>
</ul>
<p>U moř&iacute; může b&yacute;t terminologie problematick&aacute;, protože často je zaměněna předev&scaron;&iacute;m z&nbsp;historick&yacute;ch důvodů s&nbsp;pojmem <strong>z&aacute;liv</strong>, kter&yacute; je definov&aacute;n jako č&aacute;st oce&aacute;nu či moře zasahuj&iacute;c&iacute; do pevniny. Př&iacute;kladem je Guinejsk&yacute; či Alja&scaron;sk&yacute; z&aacute;liv, ty jsou totiž ve skutečnosti okrajov&yacute;mi moři.</p>
<hr /><h2>Jednotliv&eacute; oce&aacute;ny</h2>
<p>V&nbsp;současnosti (2018) se vymezuje <strong>5 d&iacute;lč&iacute;ch oce&aacute;nů</strong>. Jde o Tich&yacute;, Atlantsk&yacute;, Indick&yacute;, Severn&iacute; ledov&yacute; a Jižn&iacute; oce&aacute;n.</p>
<h3>Tich&yacute; oce&aacute;n</h3>
<p><strong>Tich&yacute; oce&aacute;n</strong> je největ&scaron;&iacute;, zab&iacute;r&aacute; skoro polovinu světov&eacute;ho oce&aacute;nu a třetinu cel&eacute;ho zemsk&eacute;ho povrchu. Průměrn&aacute; hloubky čin&iacute; asi 4 000 m a nejhlub&scaron;&iacute; m&iacute;sto se nach&aacute;z&iacute; v&nbsp;Mari&aacute;nsk&eacute;m př&iacute;kopě (10 994 m). Dno vykazuje relativně velmi velk&eacute; v&yacute;&scaron;kov&eacute; rozd&iacute;ly, což je zapř&iacute;činěno nestabilitami zemsk&eacute; kůry. R&aacute;muje ho tzv. Ohňov&yacute; oblouk a je zde tak vysok&aacute; tektonick&aacute; aktivita. Prob&iacute;h&aacute; zde velmi <strong>čil&aacute; doprava</strong> ve směru sever-jih i v&yacute;chod-z&aacute;pad a nach&aacute;z&iacute; se zde množstv&iacute; světově v&yacute;znamn&yacute;ch př&iacute;stavů, např. Vladivostok, Shanghai, Singapur, Callo, San Francisco, Los Angeles, Vancouver a mnoho dal&scaron;&iacute;ch.</p>
<h3>Atlantsk&yacute; oce&aacute;n</h3>
<p><strong>Atlantsk&yacute; oce&aacute;n</strong> je druh&yacute; největ&scaron;&iacute;, jeho průměrn&aacute; hloubka čin&iacute; asi 3 600 m a nejhlub&scaron;&iacute; m&iacute;sto se nach&aacute;z&iacute; v&nbsp;Portorick&eacute;m př&iacute;kopu (8 486 m). Oce&aacute;nem proch&aacute;z&iacute; od severu k&nbsp;jihu esovitě prohnut&yacute; Středoatlantsk&yacute; hřbet, rovněž se zde nach&aacute;z&iacute; patrně nejv&yacute;znamněj&scaron;&iacute; oce&aacute;nsk&yacute; proud &ndash; Golfsk&yacute; proud. I zde prob&iacute;haj&iacute; velmi v&yacute;znamn&eacute; n&aacute;mořn&iacute; trasy, asi <strong>2/3 n&aacute;kladů na světě</strong> se přeprav&iacute; pr&aacute;vě tudy. Nejv&yacute;znamněj&scaron;&iacute;mi př&iacute;stavy jsou pak např&iacute;klad Rotterdam, Hamburg, Marseille, Petrohrad, New York, Buenos Aires, Kapsk&eacute; město a mnoho dal&scaron;&iacute;ch.</p>
<h3>Indick&yacute; oce&aacute;n</h3>
<p><strong>Indick&yacute; oce&aacute;n </strong>je třet&iacute;m největ&scaron;&iacute;m oce&aacute;nem, jeho průměrn&aacute; hloubka čin&iacute; asi 1&nbsp;100&nbsp;m, nejhlub&scaron;&iacute; m&iacute;sto se nach&aacute;z&iacute; v&nbsp;Sundsk&eacute;m př&iacute;kopu (7&nbsp;450&nbsp;m). Na jihu je spojen s&nbsp;Tich&yacute;m, Atlantsk&yacute;m i Jižn&iacute;m oce&aacute;nem, zat&iacute;mco na severu je siln&yacute; vliv působen&iacute; pevniny a prob&iacute;haj&iacute; zde monzuny. Přes tento oce&aacute;n je transportov&aacute;no asi <strong>1/10 n&aacute;kladů</strong>, hlavn&iacute;mi př&iacute;stavy jsou zde např&iacute;klad Aden, Kuvajt, Kar&aacute;či, Bombai, Čitagou, Colombo, Durban, Dar es Salam, Fremantle a dal&scaron;&iacute;.</p>
<h3>Jižn&iacute; oce&aacute;n</h3>
<p><strong>Jižn&iacute; oce&aacute;n</strong> (t&eacute;ž Antarktick&yacute; či Jižn&iacute; ledov&yacute;) je druh&yacute;m nejmen&scaron;&iacute;m oce&aacute;nem, průměrn&aacute; hloubka čin&iacute; asi 4&nbsp;500&nbsp;m. Obklopuje <strong>Antarktidu</strong> a jeho severn&iacute; hranice je vymezena 60. rovnoběžkou. Pro lodn&iacute; dopravu jde o <strong>rizikovou oblast</strong>, jde o tzv. <em>ječ&iacute;c&iacute; &scaron;edes&aacute;tky</em> &ndash; působ&iacute; zde siln&yacute; v&iacute;tr a větrem vzdut&eacute; vlny se ženou kolem cel&eacute; zeměkoule, aniž by narazily na pevninskou přek&aacute;žku.</p>
<h3>Severn&iacute; ledov&yacute; oce&aacute;n</h3>
<p><strong>Severn&iacute; ledov&yacute; oce&aacute;n</strong> je nejmen&scaron;&iacute;m oce&aacute;nem, průměrn&aacute; hloubka čin&iacute; asi 1&nbsp;100&nbsp;m, největ&scaron;&iacute; hloubka je pak 5&nbsp;220&nbsp;m. Středn&iacute; č&aacute;st je trvale zamrznut&aacute; a vyskytuje se zde plavaj&iacute;c&iacute; led od v&yacute;chodu k&nbsp;z&aacute;padu, led tzv. <em>driftuje</em>. Pro n&aacute;mořn&iacute; dopravu se použ&iacute;v&aacute; pouze <strong>3 až 5 měs&iacute;ců v&nbsp;roce</strong> a lodě využ&iacute;vaj&iacute; tzv. <strong>Severn&iacute; mořskou cestu</strong>. V&yacute;znamn&yacute;mi př&iacute;stavy zde jsou např&iacute;klad Murmansk, Archangeľsk, Tromso, Trondheim či Churchil.</p>
<hr /><h2>Hydrologick&eacute; raj&oacute;ny světov&eacute;ho oce&aacute;nu</h2>
<p>Dietrich v roce 1957 rozčlenil oce&aacute;n dle povrchov&yacute;ch proudů a dal&scaron;&iacute;ch krit&eacute;ri&iacute; na několik fyzickogeografick&yacute;ch oblast&iacute;, resp. hydrologick&yacute;ch raj&oacute;nů, jež jsou vyps&aacute;ny n&iacute;že. Jde o raj&oacute;ny:</p>
<ul>
<li>pas&aacute;tov&yacute;ch proudů,</li>
<li>rovn&iacute;kov&yacute;ch protiproudů,</li>
<li>monzunov&yacute;ch proudů,</li>
<li>subtropick&yacute;ch oblast&iacute;,</li>
<li>voln&yacute;ch proudů,</li>
<li>proudů z&aacute;padn&iacute;ch větrů,</li>
<li>&scaron;elfov&yacute;ch moř&iacute;</li>
<li>a pol&aacute;rn&iacute; raj&oacute;ny.</li>
</ul>
<h3><strong>Raj&oacute;ny pas&aacute;tov&yacute;ch proudů</strong></h3>
<p><strong>Raj&oacute;ny pas&aacute;tov&yacute;ch proudů </strong>se vyznačuj&iacute; t&iacute;m, že zde převl&aacute;daj&iacute; z&aacute;padn&iacute; proudy (voda se pohybuje k&nbsp;z&aacute;padu), převažuj&iacute; tu totiž pas&aacute;tov&eacute; větry. D&aacute;le se rozděluj&iacute; na tyto podraj&oacute;ny:</p>
<ul>
<li><strong>v&yacute;chodn&iacute; </strong>(such&eacute; vzduchov&eacute; masy, pou&scaron;tě u pobřež&iacute; &ndash; Namib, Atacama),</li>
<li><strong>centr&aacute;ln&iacute;</strong>,</li>
<li><strong>z&aacute;padn&iacute; </strong>(vlhk&eacute; vzduchov&eacute; masy, tropick&aacute; vegetace v&nbsp;Braz&iacute;lii atd.).</li>
</ul>
<h3><strong>Raj&oacute;ny rovn&iacute;kov&yacute;ch protiproudů</strong></h3>
<p><strong>Raj&oacute;ny rovn&iacute;kov&yacute;ch protiproudů</strong> se vyznačuj&iacute; t&iacute;m, že se zde po cel&yacute; rok či sez&oacute;nně vyskytuj&iacute; povrchov&eacute; proudy pohybuj&iacute;c&iacute; se od z&aacute;padu na v&yacute;chod mezi severo a jihorovn&iacute;kov&yacute;mi proudy. Tyto proudy vyrovn&aacute;vaj&iacute; odnos vody pas&aacute;tov&yacute;mi proudy ve v&yacute;chodn&iacute; č&aacute;stech oce&aacute;nu, a proto se naz&yacute;vaj&iacute; <strong>vyrovn&aacute;vaj&iacute;c&iacute; proudy</strong>. Rychlost těchto proudů dosahuje až 50&ndash;150 cm.s<sup>-1</sup> (jedny z největ&scaron;&iacute;ch hodnot v oce&aacute;nu). Rovn&iacute;kov&yacute; protiproud je nejv&yacute;razněj&scaron;&iacute; v Tich&eacute;m oce&aacute;nu, kde dosahuje &scaron;&iacute;řku až 500 km a d&eacute;lku 15 000 km.</p>
<h3><strong>Raj&oacute;ny monzunov&yacute;ch proudů</strong></h3>
<p><strong>Raj&oacute;ny monzunov&yacute;ch proudů</strong> se vyznačuj&iacute; t&iacute;m, že změna monzunov&yacute;ch větrů zde podmiňuje i změnu směru povrchov&yacute;ch proudů v dan&yacute;ch oblastech světov&eacute;ho oce&aacute;nu. To podmiňuje tak&eacute; chemickou a fyzik&aacute;ln&iacute; změnu vlastnost&iacute; vody. D&aacute;le se rozděluj&iacute; na tyto podraj&oacute;ny:</p>
<ul>
<li><strong>tropick&yacute;,</strong></li>
<li><strong>mimotropick&yacute;.</strong></li>
</ul>
<h3><strong>Raj&oacute;ny subtropick&yacute;ch oblast&iacute;</strong></h3>
<p><strong>Raj&oacute;ny subtropick&yacute;ch oblast&iacute;</strong> jsou přechodn&yacute;mi oblastmi mezi raj&oacute;ny pas&aacute;tov&yacute;ch proudů a z&aacute;padn&iacute;ch driftov&yacute;ch proudů. Pas&aacute;tov&eacute; a z&aacute;padn&iacute; proudy je obt&eacute;kaj&iacute; v&nbsp;anticyklon&aacute;ln&iacute;m směru, což podmiňuje nahromaděn&iacute; lehč&iacute; vrchn&iacute; vrstvy vody v&nbsp;centr&aacute;ln&iacute;ch č&aacute;stech raj&oacute;nů. Je zde mocn&aacute; vrstva vody se zv&yacute;&scaron;enou salinitou a teplotou, ž&aacute;dn&eacute; raj&oacute;ny t&eacute;ž nemaj&iacute; v&nbsp;hloubce 200 až 400 m vy&scaron;&scaron;&iacute; salinitu a teplotu než pr&aacute;vě zde. Jsou <strong>chud&eacute; na život</strong> v&nbsp;důsledku <strong>nedostatku vertik&aacute;ln&iacute; v&yacute;měny</strong> (v severn&iacute;ch či jižn&iacute;ch č&aacute;stech raj&oacute;nů trochu bohat&scaron;&iacute;, protože vy&scaron;&scaron;&iacute; variabilita teploty podporuje vět&scaron;&iacute; vertik&aacute;ln&iacute; v&yacute;měnu).</p>
<h3><strong>Raj&oacute;ny voln&yacute;ch proudů</strong></h3>
<p><strong>Raj&oacute;ny voln&yacute;ch proudů</strong> se vyznačuj&iacute; v&yacute;skytem odtokov&yacute;ch proudů vznikaj&iacute;c&iacute;ch v&nbsp;důsledku nahromaděn&iacute; vody pas&aacute;tov&yacute;mi větry v&nbsp;z&aacute;padn&iacute;ch č&aacute;stech oce&aacute;nů. Patř&iacute; mezi ně např&iacute;klad Golfsk&yacute; proud či Kuro-&scaron;io, tepl&eacute; proudy s&nbsp;relativně vysokou rychlost&iacute;.</p>
<h3><strong>Raj&oacute;ny proudů z&aacute;padn&iacute;ch větrů</strong></h3>
<p><strong>Raj&oacute;ny proudů z&aacute;padn&iacute;ch větrů</strong> se vyznačuj&iacute; t&iacute;m, že nad proudy je cirkulace vzduchu velmi proměnliv&aacute;, je zde čast&yacute; v&yacute;skyt cyklon&aacute;ln&iacute;ch situac&iacute; a s&nbsp;ohledem na v&yacute;skyt kolem 40. rovnoběžky se jim často ř&iacute;k&aacute; <em>řvouc&iacute; čtyřic&iacute;tky</em>. Sr&aacute;žky převy&scaron;uj&iacute; v&yacute;par, je zde tak niž&scaron;&iacute; salinita. V&yacute;stupn&eacute; proudy přin&aacute;&scaron;&iacute; pro vodn&iacute; živočichy množstv&iacute; živin a jsou tak <strong>bohat&eacute; na život</strong>.</p>
<h3><strong>Pol&aacute;rn&iacute; raj&oacute;ny</strong></h3>
<p><strong>Pol&aacute;rn&iacute; raj&oacute;ny</strong> děl&iacute;me na dva z&aacute;kladn&iacute; podraj&oacute;ny:</p>
<ul>
<li><strong>vnitřn&iacute;</strong> (pokryt&eacute; ledem, na severn&iacute; poloukouli tabulov&yacute; led, na jižn&iacute; hodně ledov&yacute;ch vrchů ze &scaron;elfov&eacute;ho ledovce)</li>
<li><strong>vněj&scaron;&iacute; </strong>(pokryt&eacute; ledem v&nbsp;zimě, jejich hranice např. Severoatlantick&yacute;m proudem zatlačena k&nbsp;79&deg; s. &scaron;., zat&iacute;mco Labradorsk&yacute; proud ji posunuje k&nbsp;46&deg; s. &scaron;.)</li>
</ul>
<h3><strong>Raj&oacute;ny &scaron;elfov&yacute;ch moř&iacute;</strong></h3>
<p><strong>Raj&oacute;ny &scaron;elfov&yacute;ch moř&iacute;</strong> jsou přev&aacute;žně moře s&nbsp;hloubkou do 200&nbsp;m. Zab&iacute;raj&iacute; asi 7,8 % povrchu světov&eacute;ho oce&aacute;nu, ale jen 0,2 % jeho objemu. Vlastnosti silně ovlivněny přilehlou pevninou a okoln&iacute;m oce&aacute;nem.</p>
<hr /><h2>Vodn&iacute; hmoty</h2>
<p>Vodn&iacute; hmota je dostatečně rozs&aacute;hl&yacute; objem světov&eacute;ho oce&aacute;nu, kter&eacute; vznikli v&nbsp;př&iacute;slu&scaron;n&yacute;ch fyzickogeografick&yacute;ch a časov&yacute;ch podm&iacute;nk&aacute;ch a maj&iacute; sv&eacute; charakteristick&eacute; vlastnosti (sledov&aacute;na předev&scaron;&iacute;m teplota, salinita, obsah kysl&iacute;ku, optick&eacute; a biologick&eacute; kvality vody). Hranice mezi jednotliv&yacute;mi hmotami je naz&yacute;v&aacute;na <strong>oce&aacute;nsk&aacute; fronta</strong>.</p>
<p>V&nbsp;z&aacute;kladu lze odli&scaron;it:</p>
<ul>
<li><strong>povrchovou vodn&iacute; hmotu</strong> = sah&aacute; po hranici vertik&aacute;ln&iacute;ho prom&iacute;ch&aacute;v&aacute;n&iacute; vody, tj. <strong>oce&aacute;nsk&aacute; troposf&eacute;ra</strong></li>
<li><strong>hlubinn&aacute; vodn&iacute; hmota</strong> = je relativně homogenn&iacute;, tj. <strong>oce&aacute;nsk&aacute; stratosf&eacute;ra</strong></li>
</ul>
<p>Ve světov&eacute;m oce&aacute;nu se pak obvykle odli&scaron;uj&iacute; vodn&iacute; hmoty:</p>
<ul>
<li><strong>povrchov&eacute;</strong> d&aacute;le zon&aacute;lně členěn&eacute; na:
<ul>
<li><strong>rovn&iacute;kov&eacute;</strong></li>
<li><strong>tropick&eacute;</strong></li>
<li><strong>subpol&aacute;rn&iacute;</strong></li>
<li><strong>pol&aacute;rn&iacute;</strong></li>
</ul>
</li>
<li><strong>středn&iacute; </strong>(tj. přechodn&eacute;)</li>
<li><strong>hlubinn&eacute;</strong></li>
<li><strong>dnov&eacute;</strong></li>
</ul>
<p>Poloha oce&aacute;nsk&yacute;ch front je pak proměnliv&aacute; v&nbsp;z&aacute;vislosti na lok&aacute;ln&iacute;ch podm&iacute;nk&aacute;ch.</p>
    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Členění světového oceánu</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/svetovy-ocean/"><button className="inv">&larr; Co je světový oceán?</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
